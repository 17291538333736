import React, { useState, useEffect } from 'react';

const PointBreakdown = ({ fixture }) => (
  <div className="mt-2 p-2 bg-gray-100 rounded">
    <ul className="text-sm">
      <li>Minutes: {fixture.minutes > 60 ? 2 : fixture.minutes > 0 ? 1 : 0}</li>
      <li>Goals: {fixture.goals_scored * 4}</li>
      <li>Assists: {fixture.assists * 3}</li>
      <li>Clean Sheets: {fixture.clean_sheets * 4}</li>
      <li>Goals Conceded: {Math.floor(fixture.goals_conceded / 2) * -1}</li>
      <li>Own Goals: {fixture.own_goals * -2}</li>
      <li>Penalties Saved: {fixture.penalties_saved * 5}</li>
      <li>Penalties Missed: {fixture.penalties_missed * -2}</li>
      <li>Yellow Cards: {fixture.yellow_cards * -1}</li>
      <li>Red Cards: {fixture.red_cards * -3}</li>
      <li>Bonus: {fixture.bonus}</li>
    </ul>
  </div>
);

const calculateFixtureRound = (fixture) => {
  // Similar to Rails logic: (fixture / 10.0).ceil()
  return Math.ceil(fixture.id / 10);
};

const PlayerDetails = ({ playerId, onClose }) => {
  const [playerData, setPlayerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedFixtures, setExpandedFixtures] = useState({});

  useEffect(() => {
    const fetchPlayerData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`/api/player-details/${playerId}`, {
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setPlayerData(data.data);
      } catch (err) {
        setError('Failed to fetch player data');
        console.error('Error fetching player data:', err);
      } finally {
        setLoading(false);
      }
    };

    if (playerId) {
      fetchPlayerData();
    }
  }, [playerId]);

  if (loading) return <div className="text-center py-4">Loading...</div>;
  if (error) return <div className="text-center py-4 text-red-500">{error}</div>;
  if (!playerData) return null;

  const processPlayerHistory = (history) => {
    // Group fixtures by calculated round and sum points
    return history.reduce((acc, fixture) => {
      const round = calculateFixtureRound(fixture);
      if (!acc[round]) {
        acc[round] = {
          round,
          total_points: 0,
          minutes: 0,
          goals_scored: 0,
          assists: 0,
          clean_sheets: 0,
          goals_conceded: 0,
          own_goals: 0,
          penalties_saved: 0,
          penalties_missed: 0,
          yellow_cards: 0,
          red_cards: 0,
          bonus: 0
        };
      }
      
      // Sum up all stats for the round
      Object.keys(acc[round]).forEach(key => {
        if (key !== 'round') {
          acc[round][key] += fixture[key] || 0;
        }
      });
      
      return acc;
    }, {});
  };

  const currentSeason = playerData ? processPlayerHistory(playerData.history) : null;

  const calculateForm = () => {
    const recentMatches = playerData.history.slice(0, 5);
    const totalPoints = recentMatches.reduce((sum, match) => sum + match.total_points, 0);
    return (totalPoints / recentMatches.length).toFixed(1);
  };

  const statRow = (label, value, subValue = null) => (
    <div className="flex flex-col items-center">
      <div className="text-xs text-gray-500">{label}</div>
      <div className="text-lg font-bold">{value}</div>
      {subValue && <div className="text-xs text-gray-500">{subValue}</div>}
    </div>
  );

  const toggleFixtureBreakdown = (gameRound) => {
    setExpandedFixtures(prev => ({
      ...prev,
      [gameRound]: !prev[gameRound]
    }));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white shadow-md rounded-lg p-6 max-w-md mx-auto max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <div>
            <p className="text-sm text-gray-500">{playerData.position}</p>
            <h2 className="text-2xl font-bold">{playerData.web_name}</h2>
            <p className="text-sm text-gray-500">{playerData.teams.find(team => team.id === playerData.club_id).name}</p>
          </div>
          <button onClick={onClose} className="text-2xl">&times;</button>
        </div>
        {playerData.news && (
          <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
            <p className="font-bold">Warning</p>
            <p>{playerData.news}</p>
          </div>
        )}
        <div className="flex justify-center mb-4">
          <img 
            src={`https://resources.premierleague.com/premierleague/photos/players/250x250/p${playerData.photo ? playerData.photo.replace('.jpg', '.png') : 'missing.png'}`}
            alt={playerData.web_name} 
            className="w-28 h-36 object-cover"
          />
        </div>
        <div className="grid grid-cols-2 gap-4 mb-4">
          {statRow("Form", calculateForm())}
          {statRow("Pts / Match", currentSeason.minutes === 0 ? "0" : (currentSeason.total_points / currentSeason.minutes * 90).toFixed(1))}
        </div>
        <div className="grid grid-cols-2 gap-4 mb-4">
          {statRow("Total Pts", currentSeason.total_points)}
          {statRow("Total Bonus", currentSeason.bonus)}
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h3 className="font-semibold mb-2">Recent Form</h3>
            <ul>
              {playerData.history.slice(-5).map((game, index) => (
                <li key={index} className="mb-2">
                  <div 
                    className="flex justify-between items-center cursor-pointer hover:bg-gray-100 p-1 rounded"
                    onClick={() => toggleFixtureBreakdown(game.round)}
                  >
                    <span>GW{game.round}: {game.total_points} pts</span>
                    <span className={`text-blue-600 transform transition-transform duration-200 ${expandedFixtures[game.round] ? 'rotate-180' : ''}`}>
                      &#94;
                    </span>
                  </div>
                  {expandedFixtures[game.round] && <PointBreakdown fixture={game} />}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="font-semibold mb-2">Upcoming Fixtures</h3>
            <ul>
              {playerData.fixtures.slice(0, 5).map((fixture, index) => {
                const isHome = fixture.team_a !== playerData.club_id;
                const opponentTeam = playerData.teams.find(team => team.id === (isHome ? fixture.team_a : fixture.team_h));
                return (
                  <li key={index} className="mb-1">
                    GW{fixture.event}: {isHome ? 'vs' : '@'} {opponentTeam.name}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerDetails;
